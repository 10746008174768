import React from 'react'

import ShareYourIdea from '../../../react4xp/components/ShareYourIdea'

function TransportCardsPart({ text, cta, images }) {
    return (
		<ShareYourIdea
            section={{
                bgColor: 'white'
            }}
            box={{
                image: images.delMed,
                alt: 'Del Med',
                content: text,
                cta: cta
            }}
        />
    )
}

export default (props) => <TransportCardsPart {...props} />
